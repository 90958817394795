module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dan Sack Web Dev Portfolio","short_name":"Dan Sack Portfolio","start_url":"/","background_color":"#1E2749","theme_color":"#1E2749","display":"minimal-ui","icon":"src/images/DScrop.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.1},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
